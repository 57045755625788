import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

const blogQuery = graphql`
    query {
        homePost: allMarkdownRemark(
            filter: { frontmatter: { kind: { eq: null } } }
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 3
        ) {
            edges {
                node {
                    excerpt
                    frontmatter {
                        title
                        image
                        subtitle
                        date(formatString: "dddd MMMM Do")
                        description
                        slug
                        author
                    }
                    fields {
                        readingTime {
                            text
                        }
                    }
                }
            }
        }
        homePostAll: allMarkdownRemark(
            filter: { frontmatter: { kind: { eq: null } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt
                    frontmatter {
                        title
                        image
                        subtitle
                        date(formatString: "dddd MMMM Do")
                        description
                        slug
                        author
                    }
                    fields {
                        readingTime {
                            text
                        }
                    }
                }
            }
        }
    }
`;

export const BlogSection = (props) => {
    // Refactor this later, we should have a single component that works in multiple widths -- doing this for speed.
    const postQuery = props.showAll ? "homePostAll" : "homePost";
    const posts = useStaticQuery(blogQuery)[postQuery].edges.map((x) => x.node);
    return (
        <section className="hero">
            <div className="hero-body">
                {posts.map((blogData) => (
                    <div key={blogData.frontmatter.slug} className="container">
                        <div className="columns is-vcentered">
                            <div className="column">
                                <Link key={blogData.frontmatter.slug} to={blogData.frontmatter.slug}>
                                    <div className="card-body mb-2">
                                        <div className="content p-sm">
                                            <div className="title has-text-dark is-3">{blogData.frontmatter.title}</div>
                                            <div className="subtitle has-text-dark is-6">
                                                {blogData.frontmatter.author}, {blogData.frontmatter.date}
                                            </div>
                                            <p className="subtitle is-italic has-text-dark is-6">
                                                {blogData.frontmatter.description}
                                            </p>
                                            <p className="has-text-dark">{blogData.excerpt}</p>
                                            <div className="tags has-addons">
                                                <span className="tag is-success">
                                                    {blogData.fields.readingTime.text}, continued here.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="column is-one-third">
                                <Link key={blogData.frontmatter.slug} to={blogData.frontmatter.slug}>
                                    <div className="card-image">
                                        <img className="image" src={blogData.frontmatter.image} />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

BlogSection.propTypes = {
    showAll: PropTypes.bool,
};
