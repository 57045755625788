import { Link } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { BlogSection } from "../components/BlogSection";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";

import HomePipeline from "../images/home-pipeline.svg";
import HomeInstant from "../images/home-instant.svg";
import HomeCustomer from "../images/home-customer.svg";

const homeQuery = graphql`
    query {
        placeholderImage: file(relativePath: { eq: "galaxy-pro-splash.png" }) {
            childImageSharp {
                fluid(maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`;

const SplashImage = () => {
    const data = useStaticQuery(homeQuery);
    return <Img fluid={data.placeholderImage.childImageSharp.fluid} />;
};

const EventBox = (props) => (
    <div className="container has-text-centered">
        <h1 className="title">${props.title}</h1>
        <div className="columns is-centered">
            <div className="column is-four-fifths has-text-centered">
                <div className="tile is-parent">
                    <div className="tile is-child box">
                        <div className="columns">
                            <div className="is-vertical-center column has-background-dark is-narrow">
                                <h1 className="title is-5 has-text-white">{props.date}</h1>
                            </div>
                            <div className="is-vertical-center column">
                                <div className="container has-text-centered">{props.location}</div>
                            </div>
                            <div className="is-narrow is-vertical-center">
                                <FaAngleRight size="2em" />
                            </div>
                            <div className="is-vertical-center column is-three-fifths">
                                <div className="container">
                                    (CHUNKED OUT TO SAVE STYLING -- CONFIGURE PROPS)
                                    <a href="https://bcc2020.sched.com/event/d6SU/bof-where-is-the-bar">
                                        <h1 className="title is-5">July 19 15:15 BoF: Where is the bar?</h1>
                                        <h2 className="subtitle is-6">come join us for a virtual meet and greet</h2>
                                    </a>
                                    <br />
                                    <p className="bold">
                                        <a href="https://bcc2020.galaxyworks.io/initial/galaxy">
                                            Explore our BCC demo server.
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

EventBox.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    date: PropTypes.string,
    location: PropTypes.string,
};

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <section className="hero is-primary is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-two-fifths">
                            <h1 className="title alternative is-1">
                                GALAXY <span className="has-text-warning">PRO</span>
                            </h1>
                            <h2 className="subtitle">Explore your Data!</h2>
                            <p>Instant access to data analysis pipelines.</p>
                            <p>Exploratory analysis with validated tools.</p>
                            <p>Professional services to ensure customer success.</p>
                            <br />
                            <br />
                            <Link className="button is-warning" to="/contact">
                                Contact Us
                            </Link>
                        </div>
                        <div className="column is-three-fifths">
                            <SplashImage />
                        </div>
                    </div>
                    <div className="has-text-centered is-size-1">
                        <FaAngleDown />
                    </div>
                </div>
            </div>
        </section>

        <BlogSection />
        <section className="hero is-light">
            <div className="hero-body">
                <div className="container">
                    <h1 className="title alternative is-2 has-text-centered">Get your results faster!</h1>
                    <p className="subtitle has-text-centered mt-2 mb-1">
                        Galaxy Pro is a managed Galaxy service for analyzing genomic data.
                    </p>
                    <p className="subtitle has-text-centered">
                        Let us handle the software and infrastructure tasks so you can focus on data analysis.
                    </p>
                </div>
            </div>
        </section>
        <section className="hero is-light">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-half p-lg">
                            <HomeInstant style={{ width: "80%" }} />
                        </div>
                        <div className="column">
                            <div className="content m-l-lg">
                                <h2>Instant Galaxy Pro access</h2>
                                <div>
                                    With Galaxy Pro, get seamless access to a data analysis platform tailored to omics.
                                    <ul>
                                        <li>
                                            <span className="has-text-weight-bold">No system administration:</span>{" "}
                                            Instead of weeks and months to set up and maintain Galaxy, receive a link
                                            that just works.
                                        </li>
                                        <li>
                                            <span className="has-text-weight-bold">Don&apos;t wait in the queue:</span>{" "}
                                            With Galaxy Pro, your jobs do not wait in the queue and there are no storage
                                            quotas.
                                        </li>
                                        <li>
                                            <span className="has-text-weight-bold">
                                                Infrastructure to meet your needs:
                                            </span>{" "}
                                            If your jobs call for high memory or many CPUs to get the job done quickly,
                                            the sky is the limit.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="hero is-light">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column">
                            <div className="content m-l-lg">
                                <h2>Readily use Pro pipelines</h2>
                                <div>
                                    Upload data and run jobs, from R&amp;D to production without designing pipelines
                                    while not being restricted in possibilities.
                                    <ul>
                                        <li>
                                            <span className="has-text-weight-bold">Validated tools:</span> Galaxy Pro
                                            comes with a suite of tools that have been properly installed and
                                            operationally validated.
                                        </li>
                                        <li>
                                            <span className="has-text-weight-bold">Topic-based workflows:</span>{" "}
                                            Don&apos;t spend 3-6 months developing a pipeline. As a subscriber, you get
                                            access to Pro workflows as high-quality pipelines using best-practice tools
                                            and values.
                                        </li>
                                        <li>
                                            <span className="has-text-weight-bold">Exploratory mode:</span> Want to look
                                            at your own data or tweak parameters? Do this easily in the exploration mode
                                            and save your changes for future runs.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="column is-half p-lg">
                            <HomePipeline style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="hero">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-half p-lg">
                            <HomeCustomer style={{ width: "70%" }} />
                        </div>
                        <div className="column">
                            <div className="content m-l-lg">
                                <h2>Focused on customer success</h2>
                                <div>
                                    Infrastructure, tools, and pipelines are a foundation for a successful analysis but
                                    the reality is that each research project comes with its own requirements.
                                    <ul>
                                        <li>
                                            <span className="has-text-weight-bold">Proactive collaboration:</span>
                                            &nbsp;GalaxyWorks will follow you on your project journey to ensure your
                                            Galaxy Pro configuration matches the project.
                                        </li>
                                        <li>
                                            <span className="has-text-weight-bold">Onboarding and training:</span> We
                                            work with you to get started and continue to stand by your side.
                                        </li>
                                        <li>
                                            <span className="has-text-weight-bold">Customization:</span> Adding new or
                                            custom tools as well as pipeline development is available.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default IndexPage;
